<template>
    <div class="main">
      <div class="banner">
        <div class="banner-bg"></div>
        <div class="banner-wrapper">
          <div class="banner-title">灯塔智能测评系统</div>
          <div class="banner-tip">特点</div>
          <div class="text-block">
            <div class="banner-intro">
              <p> 新高考选科(3+1+2)</p>
            </div>
          </div>
          <div
            class="banner-start lj-btn lj-btn-third"
            @click="onTestEntryClick(false, 2)"
          >
          新高考选科(3+1+2)
          </div>
        </div>
      </div>
      <div class="main-title" id="products">产品介绍</div>
      <div class="products">
        <div class="product">
          <div class="left">
            <div class="product-title"> 新高考选科(3+1+2)</div>
          </div>
          <div class="right">
            <div class="img-wrapper" id="product-1"></div>
          </div>
        </div>
        <div class="product">
          <div class="left">
            <div class="img-wrapper" id="product-4"></div>
          </div>
          <div class="right">
            <div class="product-title"> 新高考选科(3+1+2)</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: '',
    data: function () {
      return {}
    },
    computed: {},
    methods: {
      onTestEntryClick: function () {
        this.$emit('enterass','xuanke')
      }
    },
    mounted: function () {},
    components: {}
  }
  </script>
  
  <style lang="less">
  </style>
  